import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import EnergyLabelAndPrice1 from '@images/components/energy-label/EnergyLabelAndPrice1_x2.png';
import EnergyLabelAndPrice2 from '@images/components/energy-label/EnergyLabelAndPrice2_x2.png';
import EnergyLabelLinks1 from '@images/components/energy-label/EnergyLabelLinks1_x2.png';
import EnergyLabelLinks2 from '@images/components/energy-label/EnergyLabelLinks2_x2.png';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const ExampleBox = makeShortcode("ExampleBox");
const EnergyLabelExample = makeShortcode("EnergyLabelExample");
const EnergyLabelSizeExample = makeShortcode("EnergyLabelSizeExample");
const ImageWithCaption = makeShortcode("ImageWithCaption");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#how-to-use-energy-labels"
            }}>{`How to use energy labels`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#types-of-energy-label"
            }}>{`Types of energy label`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#energy-label-and-price"
            }}>{`Energy label and price`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#energy-label-links"
            }}>{`Energy label links`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <hr></hr>
    <h2 {...{
      "id": "how-to-use-energy-labels",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-use-energy-labels",
        "aria-label": "how to use energy labels permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`How to use energy labels`}</h2>
    <p>{`When using energy labels:`}</p>
    <ul>
      <li parentName="ul">{`The energy label and product information sheet must be displayed on all pages where the price of a product is shown and where that product can be ordered`}</li>
      <li parentName="ul">{`The energy label must be visually close to the price of the product`}</li>
      <li parentName="ul">{`The arrow graphic must show the energy class of the product in both colour and letter`}</li>
      <li parentName="ul">{`The energy lavel must have a link to the product information sheet`}</li>
      <li parentName="ul">{`The design of the energy label cannot be edited as these labels have been determined by government guidelines`}</li>
    </ul>
    <p>{`For more information on energy labels, read the `}<a parentName="p" {...{
        "href": "https://www.energylabel.org.uk/fileadmin/uk/Energy_Label_Retail_Guide_update_FINAL_-_Copy.pdf"
      }}>{`government's guidance on energy labels`}</a>{`.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "types-of-energy-label",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#types-of-energy-label",
        "aria-label": "types of energy label permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Types of energy label`}</h2>
    <p>{`The energy label has 7 ratings; A, B, C, D, E, F and G.`}</p>
    <ExampleBox mdxType="ExampleBox">
  <EnergyLabelExample mdxType="EnergyLabelExample" />
    </ExampleBox>
    <h3>{`Sizes`}</h3>
    <p>{`The energy label is available in two different sizes.`}</p>
    <p>{`The medium size is used on product description pages (PDPs), listing pages and product cards. The small size is used in listing pages and smaller product cards.`}</p>
    <ExampleBox mdxType="ExampleBox">
  <EnergyLabelSizeExample mdxType="EnergyLabelSizeExample" />
    </ExampleBox>
    <hr></hr>
    <h2 {...{
      "id": "energy-label-and-price",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#energy-label-and-price",
        "aria-label": "energy label and price permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Energy label and price`}</h2>
    <p>{`The size of the letter within the arrow must always be greater than or equal to the price label of the product.`}</p>
    <ImageWithCaption image={EnergyLabelAndPrice1} type="png" alt="Example of letter within the arrow being greater than or equal to the price of the product on screen" mdxType="ImageWithCaption" />
    <ImageWithCaption image={EnergyLabelAndPrice2} type="png" alt="Example of incorrect and correct letter font size for the energy label" mdxType="ImageWithCaption" />
    <hr></hr>
    <h2 {...{
      "id": "energy-label-links",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#energy-label-links",
        "aria-label": "energy label links permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Energy label links`}</h2>
    <p>{`The energy label graphic must link to the full energy rating information label, which has the product's full energy rating details. This can either be fully displayed on the page or nested. It must be in close proximity to the price.`}</p>
    <ImageWithCaption image={EnergyLabelLinks1} type="png" alt="Example of Energy Label linking to Product Information Sheet" mdxType="ImageWithCaption" />
    <p>{`All energy labels should also be accompanied with a link labelled ‘Product information sheet’. This should open up the product information sheet.`}</p>
    <ImageWithCaption image={EnergyLabelLinks2} type="png" alt="Example of Energy Label linking to Product Information Sheet" mdxType="ImageWithCaption" />
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  Our team can answer any questions about using accordions or give you a helping
  hand with your next project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      